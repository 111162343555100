import { APIFilter } from '@/utils/api'


export default {
  async selectParteTrabajoTecnico (Vue, idparteTrabajoTecnico) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idparte_trabajo_tecnico', idparteTrabajoTecnico)
    const resp = await Vue.$api.call('parteTrabajoTecnico.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async selectDetails (Vue, idparteTrabajo) {
    let apiCalls = []
    apiCalls.push({ name: 'parteTrabajoDescargado', method: 'parteTrabajo.descargado', params: { idparte_trabajo: idparteTrabajo } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async setTecnicoPrincipal (Vue, idparteTrabajoTecnico, idparteTrabajo) {
    await Vue.$api.call(
      'parteTrabajoTecnico.setTecnicoPrincipal',
      {
        idparte_trabajo_tecnico: idparteTrabajoTecnico,
        idparte_trabajo: idparteTrabajo
      }
    )
  }
}
