<template>
  <div>
    <v-chip
      v-if="conTiempoTrabajado"
      class="ml-1 mt-1"
      small
      color="red"
      label
      dark
    >
      <v-icon
        left
        small
      >
        {{ $vuetify.icons.values.tiempoTrabajado }}
      </v-icon>
      Con tiempo trabajado
    </v-chip>
  </div>
</template>

<script>
import { TFACTURACION_KILOMETROS } from '@/utils/consts'

export default {
  props: {
    conTiempoTrabajado: {
      type: Boolean,
    },
  },
  data () {
    return {
      TFACTURACION_KILOMETROS,
    }
  },
  computed: {
  },
}
</script>
