<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
      >
        <template slot="extraSubtitle">
          <extra-subtitle
            :con-tiempo-trabajado="formattedItem.tiene_tiempo_trabajado"
          />
        </template>
      </b10-view-summary>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './ParteTrabajoTecnicoViewData'
import { PARTE_TRABAJO_TECNICO } from '@/utils/consts'
import ExtraSubtitle from './components/ExtraSubtitle'
import _ from '@/utils/lodash'

export default {
  components: {
    ExtraSubtitle
  },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: {
      },
      toolbarOptions: {
        principal: {
          title: 'Establecer como principal',
          visible: true,
          icon: 'check',
        },
      },
      descargado: true
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.title   = item.nombre_y_apellido
        item.subtitle = `Artículo de facturación: ${item.articulo}`
        if (item.principal) {
          item.badge = 'Principal'
          item.badgeColor = PARTE_TRABAJO_TECNICO.colores.principal
        }
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.toolbarOptions.principal.visible = !this.item.dataset.principal && !this.descargado
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectParteTrabajoTecnico(this, this.routeParams.idparte_trabajo_tecnico)
        const resp = await Data.selectDetails(this, this.routeParams.idparte_trabajo)
        this.descargado = resp.data.parteTrabajoDescargado.result
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
          this.title = 'Técnico asignado'
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.principal) {
        this.setPrincipal()
      }
    },
    async setPrincipal () {
      const res = await this.$alert.showConfirm(`¿Deseas asignar a ${this.item.dataset.nombre_y_apellido} como técnico principal`)
      if (res) {
        await Data.setTecnicoPrincipal(
          this,
          this.item.dataset.idparte_trabajo_tecnico,
          this.routeParams.idparte_trabajo
        )
        this.$alert.showSnackbarSuccess(`Se ha asignado a ${this.item.dataset.nombre_y_apellido} como técnico principal`)
        this.loadPage()
      }
    },
  },
}
</script>
